<template>
  <div class="home-page" style="padding-top: 56px">
    <HomeBannerComponent />
    <AboutComponent />
    <div class="reference">
      <h2>Referenties</h2>
      <ReferenceComponent />
    </div>
    <ContactComponent />
  </div>
</template>

<style lang="scss" scoped>
.reference {
  background-color: #dfdfdf;
  padding: 25px;

  h2,
  p {
    text-align: center;
    width: 70%;
    margin: 0 auto;
  }
}
</style>