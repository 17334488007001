<template>
  <div>
    <HeaderComponent :pages="pages" :setActivePage="setActivePage" />
    <router-view />
    <FooterComponent :pages="pages" :setActivePage="setActivePage" />
  </div>
</template>

<script>
import HeaderComponent from './components/standard/HeaderComponent.vue';
import FooterComponent from './components/standard/FooterComponent.vue';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent
  },
  data() {
    return {
      pages: [
        { link: { text: 'Home', url: '/' }, pageTitle: 'Home' },
        { link: { text: 'Wie en wat', url: '/about' }, pageTitle: 'Wie en wat' },
        { link: { text: 'Referenties', url: '/references' }, pageTitle: 'Referenties' },
        { link: { text: 'Mijn projecten', url: '/gallery' }, pageTitle: 'Projecten' },
        { link: { text: 'Tarieven', url: '/prices' }, pageTitle: 'Tarieven' },
        { link: { text: 'Contact', url: '/contact' }, pageTitle: 'Contact' }
      ]
    };
  },
  methods: {
    setActivePage(index) {
      this.$router.push(this.pages[index].link.url);
    }
  }
};
</script>

<style>
body {
  font-family: Arial, sans-serif;
}
</style>