<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light custom-navbar">
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand"><img src="favicon-downloaded.ico" alt="iambob logo">iambob</router-link>
      <button class="navbar-toggler" type="button" @click="toggleMenu" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span v-if="!isMenuOpen" class="navbar-toggler-icon"></span>
        <span v-else class="close-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item" v-for="(page, index) in pages" :key="index">
            <router-link :to="page.link.url" class="nav-link" @click="closeMenu">{{ page.link.text }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="isMenuOpen" class="fullscreen-menu">
      <ul>
        <li v-for="(page, index) in pages" :key="index">
          <router-link :to="page.link.url" @click="closeMenu">{{ page.link.text }}</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>


<script>
export default {
  props: ['pages'],
  data() {
    return {
      isMenuOpen: false
    };
  },
  mounted() {
    this.$router.beforeEach((to, from, next) => {
      this.isMenuOpen = false;
      next();
    });
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-navbar {
  position: fixed;
  z-index: 100;
  background-color: #343a40 !important;
  padding: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 76px;
}
.container-fluid {
  z-index: 99999999999999999999999999999999999;
}
.navbar-brand {
  color: #ffffff !important;
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-brand img {
  height: calc(76px - 20px);
  width: auto;
  margin-right: 10px

}
.navbar-toggler {
  border: none !important;
  outline: none;
  z-index: 999999999999;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.close-icon {
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 4l22 22M4 26L26 4'/%3E%3C/svg%3E");  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
}

.nav-link {
  color: #ffffff !important;
  font-size: 1.1rem;
  margin-right: 15px;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #ff9800 !important;
}

.nav-item:last-child .nav-link {
  margin-right: 0;
}

.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 76px);
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  margin-top: 76px;
}

.fullscreen-menu ul {
  list-style: none;
  padding: 0;
}

.fullscreen-menu li {
  margin: 20px 0;
  text-align: center;
}

.fullscreen-menu a {
  color: #ffffff;
  font-size: 2rem;
  text-decoration: none;
  transition: color 0.3s;
}

.fullscreen-menu a:hover {
  color: #ff9800;
}
</style>