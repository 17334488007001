import { createRouter, createWebHashHistory } from 'vue-router';

// Pages:
import HomePage from '../pages/HomePage.vue';
import AboutPage from '../pages/AboutPage.vue';
import PricesPage from '../pages/PricesPage.vue';
import ReferencePage from '../pages/ReferencePage.vue';
import ContactPage from '../pages/ContactPage.vue';
import GalleryPage from '../pages/GalleryPage.vue';
import NotFoundPage from '../pages/NotFoundPage.vue';
import ServerErrorPage from '../pages/ServerErrorPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { title: 'Home' }
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage,
    meta: { title: 'Over Mij' }
  },
  {
    path: '/prices',
    name: 'prices',
    component: PricesPage,
    meta: { title: 'Tarieven' }
  },
  {
    path: '/references',
    name: 'References',
    component: ReferencePage,
    meta: { title: 'Referenties' }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage,
    meta: { title: 'Contact' }
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: GalleryPage,
    meta: { title: 'Projecten' }
  },
  {
    path: '/500',
    name: 'ServerError',
    component: ServerErrorPage,
    meta: { title: 'Server Error' }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFoundPage,
    meta: { title: 'Error 404' }
  },
  {
    path: '/gallery/:category/:id',
    name: 'Album',
    component: GalleryPage,
    meta: { title: 'Album' }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + " | iambob";
  next();
});

export default router;