<template>
    <div class="prices-container">
      <h1 class="page-title">Mijn Tarieven</h1>
      <p class="intro-text">
        iamBob werkt op uurbasis, ik kan een indicatieve inschatting maken zodat u ongeveer weet waar u aan toe bent.</p>
      
      <div class="price-sections">
        <!-- Uurtarief Sectie -->
        <div class="price-card">
          <div class="card-header">
            <h2>Uurtarief</h2>
            <img src="@/assets/images/clock-icon.svg" alt="Uurtarief Icon" class="card-icon">
          </div>
          <p>Onderstaande prijzen zijn indicatief en gelden voor 2024. De prijzen kunnen worden bijgesteld naar inflatie, indien een project al loopt zal dit in overleg gebeuren.</p>
          <ul class="price-list">
            <li><strong>Standaard uurtarief:</strong> €45.45 per uur (exclusief BTW = 55 euro inclusief BTW)</li>
            <li><strong>Spoedservice:</strong> €89.00 per uur (exclusief BTW = 107.69 euro incl. BTW)</li>
          </ul>
        </div>
  
        <!-- Reiskosten Sectie -->
        <div class="price-card">
          <div class="card-header">
            <h2>Reiskosten</h2>
            <img src="@/assets/images/travel-icon.webp" alt="Reiskosten Icon" class="card-icon">
          </div>
          <p>Onderstaande prijzen zijn indicatief en per keer (dus voorrijdkosten incl reiskosten). Gerekend vanuit mijn adres (Delft, indische buurt)</p>
          <ul class="price-list">
            <li><strong>Binnen 10 km:</strong> €25,00 (incl BTW)</li>
            <li><strong>10 - 50 km:</strong> €50,00 (incl BTW)</li>
            <li><strong>Buiten 50 km: (binnen Nederland)</strong> €75,00 (incl BTW)</li>
          </ul>
        </div>
  
        <!-- Materiaalkosten Sectie -->
        <div class="price-card">
          <div class="card-header">
            <h2>Materiaalkosten</h2>
            <img src="@/assets/images/material-icon.svg" alt="Materiaalkosten Icon" class="card-icon">
          </div>
          <p>Aanschaf van materialen worden niet voorgeschoten. Bij aanvang van een project wordt afgesproken hoe we dit doen (Zelf halen of tikkie bij aanschaf).</p>
        </div>
      </div>
      
      <div class="note">
        <p></p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PricesComponent',
  };
  </script>
  
  <style scoped>
  .prices-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 10px;
  }
  
  .page-title {
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 20px;
    color: #333333;
  }
  
  .intro-text {
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 40px;
    color: #555555;
  }
  
  .price-sections {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .price-card {
    padding: 25px;
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease;
  }
  
  .price-card:hover {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  }
  
  .card-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .card-header h2 {
    flex: 1;
    font-size: 1.8em;
    color: #333333;
  }
  
  .card-icon {
    width: 40px;
    height: 40px;
    margin-left: 15px;
  }
  
  .price-list {
    list-style-type: none;
    padding: 0;
    margin: 20px 0 0 0;
  }
  
  .price-list li {
    margin-bottom: 10px;
    font-size: 1.1em;
  }
  
  .price-list li strong {
    color: #333333;
  }
  
  .note {
    margin-top: 40px;
    padding: 20px;
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
    border-radius: 8px;
    font-size: 1em;
    color: #856404;
  }
  </style>
  