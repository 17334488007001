
<template>
    <div class="contact-page" style="padding-top: 56px">
        <ContactComponent />
    </div>
</template>
  
<style lang="scss" scoped>
.contacteer-ons {
  height: calc(100vh - 100px);
  
  form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>