<template>
    <div class="reference-page" style="padding-top: 100px">
        <h2>Referenties</h2>
        <p>Welkom bij de referentiepagina van iambob.<br>Hier vindt u wat mijn klanten over
            mijn werk en aanpak zeggen. Ik ben trots op mijn werk en de tevredenheid van mijn klanten.</p>
          <p>NB: deze pagina is onder constructie, hier staan de referenties nog niet compleet.</p>
        <ReferenceComponent />
    </div>
</template>

<style lang="scss" scoped>
.reference-page {
    background-color: #f9f9f9;
    height: calc(100vh - 100px);

    h2,
    p {
        text-align: center;
        width: 70%;
        margin: 0 auto;
    }
}
</style>