<template>
  <div class="gallery-container">
    <h1>Gallery</h1>
    <p>Bekijk de werkzaamheden die door mij zijn verricht.</p>
    <p>
      NB: deze pagina is onder constructie, hier staan de projecten en de foto's nog niet
      compleet.
    </p>
    <div class="filter" v-if="!selectedProject">
      <label for="category">Categorie:</label>
      <select v-model="selectedCategory" id="category" class="category-selector">
        <option value="all">Alle</option>
        <option v-for="category in categories" :key="category" :value="category">
          {{ category }}
        </option>
      </select>
    </div>
    <div class="gallery" v-if="!selectedProject">
      <template v-if="selectedCategory === 'all'">
        <div v-for="(category, index) in projects" :key="index">
          <h2>{{ category.category }}</h2>
          <div class="photo-group">
            <div
              v-for="(project, index) in category.projects"
              :key="index"
              class="photo"
              @click="selectProject(project)"
            >
              <img :src="project.preview.url" :alt="project.preview.alt" />
              <p>{{ project.name }}</p>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <h2>{{ selectedCategory }}</h2>
        <div class="photo-group">
          <div
            v-for="(project, index) in selectedCategoryProjects"
            :key="index"
            class="photo"
            @click="selectProject(project)"
          >
            <img :src="project.preview.url" :alt="project.preview.alt" />
            <p>{{ project.name }}</p>
          </div>
        </div>
      </template>
    </div>
    <div v-else class="album">
      <button @click="backToCategory">Terug naar projecten</button>
      <h2>{{ selectedProject.name }}</h2>
      <p>{{ selectedProject.description }}</p>
      <div class="photo-group">
        <div
          v-for="(photo, index) in selectedProject.photos"
          :key="index"
          class="photo"
          @click="openModal(photo)"
        >
          <img :src="photo.url" :alt="photo.alt" />
        </div>
      </div>
    </div>

    <div v-if="showModal" class="modal" @click.self="closeModal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <img :src="modalPhoto.url" :alt="modalPhoto.alt" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "GalleryComponent",
  data() {
    return {
      selectedCategory: "all",
      selectedProject: null,
      showModal: false,
      modalPhoto: null,
      projects: [],
      previousCategory: "all",
    };
  },
  computed: {
    categories() {
      return this.projects ? this.projects.map((project) => project.category) : [];
    },
    selectedCategoryProjects() {
      const category = this.projects.find(
        (project) => project.category === this.selectedCategory
      );
      return category ? category.projects : [];
    },
  },
  created() {
    this.loadProjects();
  },
  watch: {
    "$route.params": {
      immediate: true,
      handler(newParams) {
        const { category, id } = newParams;
        if (category && id) {
          this.selectProjectById(category, id);
        }
      },
    },
    selectedCategory(newCategory) {
      if (!this.selectedProject) {
        this.previousCategory = newCategory;
      }
    },
  },
  methods: {
    async loadProjects() {
      try {
        const response = await axios.get("../../data.json");
        const data = response.data;
        if (data && data.galaryComponent && data.galaryComponent.projects) {
          this.projects = data.galaryComponent.projects;
          this.trySelectProjectFromRoute();
        } else {
          console.error("Invalid data format in data.json");
        }
      } catch (error) {
        console.error("Error loading projects:", error);
      }
    },
    trySelectProjectFromRoute() {
      const { category, id } = this.$route.params;
      if (category && id) {
        this.selectProjectById(category, id);
      }
    },
    selectProject(project) {
      const projectCategory = this.getCategoryByProjectId(project.id);
      if (projectCategory) {
        this.selectedProject = project;
        this.$router.push(`/gallery/${projectCategory}/${project.id}`);
      } else {
        console.error("Categorie niet gevonden voor project:", project);
      }
    },
    selectProjectById(category, id) {
      for (const categoryData of this.projects) {
        const project = categoryData.projects.find((p) => p.id == id);
        if (project) {
          if (categoryData.category === category) {
            this.selectedCategory = category;
            this.selectedProject = project;
            return;
          } else {
            console.warn("Categorie komt niet overeen met project:", project);
          }
        }
      }
      this.selectedProject = null;
    },
    getCategoryByProjectId(id) {
      for (const categoryData of this.projects) {
        const project = categoryData.projects.find((p) => p.id == id);
        if (project) {
          return categoryData.category;
        }
      }
      return null;
    },
    backToCategory() {
      this.selectedProject = null;
      this.selectedCategory = this.previousCategory;
      this.$router.push("/gallery");
    },
    openModal(photo) {
      this.modalPhoto = photo;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.modalPhoto = null;
    },
  },
};
</script>


<style lang="scss" scoped>
.gallery-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
  text-align: center;
  color: #2c3e50;
}

p {
  font-size: 1.2em;
  margin-bottom: 20px;
  text-align: center;
  color: #7f8c8d;
}

.filter {
  text-align: center;
  margin-bottom: 20px;
}

.filter label {
  font-size: 1.2em;
  margin-right: 10px;
}

.category-selector {
  font-size: 1em;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.category-selector:focus {
  border-color: #2c3e50;
  box-shadow: 0 0 5px rgba(44, 62, 80, 0.5);
}

.gallery {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.gallery h2 {
  text-align: center;
}

.photo-group {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.photo {
  width: 400px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.photo:hover {
  transform: scale(1.05);
}

.photo img {
  width: 100%;
  height: 250px;
  display: block;
  border-radius: 10px;
  object-fit: cover;
}

.album {
  text-align: center;
}

.album button {
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  background-color: #2c3e50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.album button:hover {
  background-color: #34495e;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 700px;
  text-align: center;
}

.modal-content img {
  width: 100%;
  height: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 25px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
