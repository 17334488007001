<template>
    <div class="not-found-page">
      <div class="content">
        <h1>404 - Pagina Niet Gevonden</h1>
        <p>De pagina die je zoekt bestaat niet.</p>
        <router-link to="/" class="home-link">Ga terug naar de homepagina</router-link>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFoundPage',
  };
  </script>
  
  <style lang="scss" scoped>
  .not-found-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
  
  .content h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .content p {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .home-link {
    font-size: 1.2rem;
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .home-link:hover {
    color: #0056b3;
  }
  </style>