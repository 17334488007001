<template>
  <footer class="footer mt-auto py-3 bg-light">
    <div class="container">
      <div class="footer-content">
        <div class="footer-section about">
          <h3>Over mij</h3>
          <p>© {{ currentYear }} iambob <br>Klusjesman & Bouwbedrijf<br>KvK 94471568<br>BTW NL 00508 8910 B82</p>
        </div>
        <div class="footer-section links">
          <h3>Navigatie</h3>
          <ul>
            <li class="nav-item" v-for="(page, index) in pages" :key="index">
            <a class="nav-link" :href="'#' + page.link.url" @click="setActivePage(index)" v-text="page.link.text"></a>
          </li>
          </ul>
        </div>
        <div class="footer-section contact">
          <h3>Contact</h3>
          <p>Email: michel@iambob.nl</p>
          <p>Telefoon: +31 6 387 58 163</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: ['pages', 'setActivePage'],
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  background-color: #dfdfdf !important; 
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  padding: 10px;
  min-width: 200px;

  h3 {
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 5px;

      a {
        text-decoration: none;
        color: #007bff;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  p {
    margin: 5px 0;
  }

  a {
    display: block;
    margin-bottom: 5px;
    color: #007bff;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>