<template>
  <div class="contacteer-ons">
    <h2>Neem voor klus wensen contact op</h2>
    Mail mij op&nbsp;<a href="mailto:michel@iambob.nl">michel@iambob.nl</a>.
    Geef duidelijk aan wat uw wens of vraag is, en geef uw naam en contactgegevens door.<br><br><br><br><br>
    <form @submit.prevent="submitForm">
      <div class="form-group mb-3">
        <label for="name" class="form-label">Uw naam</label>
        <input type="text" class="form-control" id="name" v-model="name" required>
      </div>
      <div class="form-group mb-3">
        <label for="email" class="form-label">Email</label>
        <input type="email" class="form-control" id="email" v-model="email" required>
      </div>
      <div class="form-group mb-3">
        <label for="message" class="form-label">Uw vraag</label>
        <textarea class="form-control" id="message" rows="3" v-model="message" required></textarea>
      </div>
      <button type="submit" class="btn btn-primary">Submit</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      name: '',
      email: '',
      message: ''
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await axios.post('https://iambob.nl/send_mail.php', {
          name: this.name,
          email: this.email,
          message: this.message
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        if (response.data === 'success') {
          alert('Formulier succesvol verstuurd!');
        } else {
          alert('Er is een fout opgetreden bij het versturen van het formulier.');
        }
      } catch (error) {
        alert('Er is een fout opgetreden bij het versturen van het formulier.');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.contacteer-ons {
  padding: 40px 20px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contacteer-ons h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
  font-family: 'Roboto', sans-serif;
}

.contacteer-ons form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contacteer-ons .form-group {
  margin-bottom: 15px;
  width: 100%;
  max-width: 400px;
}

.contacteer-ons label {
  display: block;
  margin-bottom: 5px;
  color: #333;
  font-family: 'Roboto', sans-serif;
}

.contacteer-ons input, .contacteer-ons textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
}

.contacteer-ons button {
  background-color: #ff9800;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  transition: background-color 0.3s;
}

.contacteer-ons button:hover {
  background-color: #e68900;
}
</style>