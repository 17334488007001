<template>
  <div class="about-page">
    <h1>Over mij</h1>
    <p>Welkom bij iambob, uw betrouwbare partner voor veel van uw bouw- en kluswerkzaamheden.<br>
     Met jarenlange ervaring in het klussen voor zichzelf en voor anderen bied ik een breed scala aan diensten,<br>
     van kleine reparaties tot grote verbouwprojecten.<br>Door de uniek combinatie van brains en twee rechterhanden sta ik klaar om op een creatieve manier uw visie werkelijkheid te maken.</p>
    <div class="team-section">
      <h2>Wie is de 'Bob'?</h2>
      <p id="whybob">Waarom bob? Naast een toespeling op de bekende bouwer, tevens een afkorting afkomstig uit mijn andere bedrijf <a href="https://iamit.nl">iamIT</a>. Dat stond voor 'building on blocks'.</p>
      <ul class="team-list">
        <li v-for="(member, index) in teamMembers" :key="index" class="team-card">
          <img :src="member.photo" :alt="member.name" class="team-photo" />
          <h3>{{ member.name }}</h3>
          <p>{{ member.role }}</p>
        </li>
      </ul>
    </div>
    <h1>Wat doe ik?</h1>
    <p>Op <a href="">mijn projecten</a> ziet u wat ik zoals gedaan heb.<br>Ik doe kleine klussen tot delen van huis renovaties.<br>
      Houdt u er rekening mee dat dit een part-time onderneming van mij is, dus ik kan geen heel huis renoveren (qua tijd).<br>
      Van schilderijtjes ophangen tot (tuin)meubelen maken, vloeren leggen, wandjes plaatsen.<br>
    Electra en gas kan ik wel maar ik ben geen erkend installateur, dus doe ik voorlopig niet.<br>
      Verder doe ik geen schilderwerk (tenzij het een klein onderdeel is van een project), geen stucwerk en geen tuinwerkzaamheden.</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      teamMembers: []
    };
  },
  created() {
    this.loadTeamMembers();
  },
  methods: {
    async loadTeamMembers() {
      try {
        const response = await axios.get('../../data.json');
        this.teamMembers = response.data.aboutComponent.teamMembers;
      } catch (error) {
        console.error('Error loading team members:', error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>

.about-page {
  padding: 40px 20px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-page h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
  font-family: 'Roboto', sans-serif;
}

.about-page p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
  font-family: 'Roboto', sans-serif;
}

.team-section {
  margin-top: 2rem;
}

.team-section h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
  font-family: 'Roboto', sans-serif;
}

.team-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 0;
  list-style-type: none;
}

.team-card {
  flex: 1 1 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.team-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.team-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 2px solid #ff9800;
}

.team-section h3 {
  font-size: 1.5rem;
  margin: 0;
  color: #333;
  font-family: 'Roboto', sans-serif;
}

.team-section p {
  font-size: 1rem;
  margin: 0.5rem 0 0;
  color: #666;
  font-family: 'Roboto', sans-serif;
}

.team-section #whybob {
  margin-bottom: 40px;
}
.team-section p:last-child {
  font-style: italic;
  color: #ff9800;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .team-card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
</style>