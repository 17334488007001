import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import VueSlickCarousel from 'vue-slick-carousel';

const requireComponent = require.context(
  './components',
  true,
  /[A-Z]\w+\.(vue|js)$/
);

const app = createApp(App);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName
    .split('/')
    .pop()
    .replace(/\.\w+$/, '');
  app.component(componentName, componentConfig.default || componentConfig);
});

app.component('VueSlickCarousel', VueSlickCarousel);

app.config.productionTip = false;
app.config.devtools = true;

app.config.errorHandler = (err, vm, info) => {
  console.error(err);
  if (err.response && err.response.status === 500) {
    router.push({ name: 'ServerError' });
  }
};

app.use(router);

app.mount('#app');