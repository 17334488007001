<template>
  <section class="begin-screen">
    <div class="overlay">
      <img src="favicon-downloaded.ico" alt="">
      <h1>IAmBob</h1>
      <p>Uw bouwer en klusjesman!</p>
    </div>
    <div class="reference-page">
      <div class="reference-section">
        <Carousel :items-to-show="itemsToShow" :wrap-around="true" :breakpoints="{ 768: { itemsToShow: 1 }, 1024: { itemsToShow: 2 } }">
          <Slide v-for="(reference, index) in references" :key="index">
            <div class="reference-card-style" @mousedown="startDrag" @mouseup="endDrag(reference)">
              <h3>{{ reference.category }}:</h3>
              <p>{{ reference.name }}</p>
              <img :src="reference.preview.url" :alt="reference.preview.alt" class="reference-photo" />
            </div>
          </Slide>
          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
        <router-link to="/gallery" class="contact-link">Bekijk alle projecten</router-link>
      </div>
    </div>
  </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';
import axios from 'axios';

export default {
  name: 'ProjectsComponent',
  components: { Carousel, Slide, Navigation, Pagination },
  data() {
    return {
      references: [],
      itemsToShow: 3,
      selectedProject: null,
      isDragging: false
    };
  },
  async created() {
    this.loadReferences();
  },
  mounted() {
    this.updateItemsToShow();
    window.addEventListener('resize', this.updateItemsToShow);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateItemsToShow);
  },
  methods: {
    async loadReferences() {
      try {
        const response = await axios.get('../../data.json');
        const projects = response.data.galaryComponent.projects;
        this.references = projects.flatMap(category => 
          category.projects
            .filter(project => project.showcase)
            .map(project => ({ ...project, category: category.category }))
        );
      } catch (error) {
        console.error('Error loading references:', error);
        this.references = []; 
      }
    },
    updateItemsToShow() {
      const width = window.innerWidth;
      if (width <= 768) {
        this.itemsToShow = 1;
      } else if (width <= 1024) {
        this.itemsToShow = 2;
      } else {
        this.itemsToShow = 3;
      }
    },
    getCategoryByProjectId(projectId) {
      const project = this.references.find(ref => ref.id === projectId);
      return project ? project.category : null;
    },
    startDrag() {
      this.isDragging = false;
    },
    endDrag(reference) {
      return () => {
        if (!this.isDragging) {
          this.openProject(reference);
        }
      };
    },
    openProject(project) {
      const projectCategory = this.getCategoryByProjectId(project.id);
      if (projectCategory) {
        this.selectedProject = project;
        this.$router.push(`/gallery/${projectCategory}/${project.id}`);
      } else {
        console.error("Categorie niet gevonden voor project:", project);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.begin-screen {
  position: relative;
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.begin-screen::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/images/michel-bob-verkleind.png');
  background-size: cover;
  background-position: center;
  filter: blur(3px) brightness(0.35) saturate(0) contrast(1.2) grayscale(0.5) opacity(0.8) drop-shadow(0 0 10px rgba(0, 0, 0, 0.5)) invert(0) sepia(0) hue-rotate(0);
  z-index: -1;
}

.begin-screen::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 56px);
  background-color: #000;
  opacity: 0.5;
  z-index: -1;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  text-align: center;
}

.contact-link {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.contact-link:hover {
  background-color: #0056b3;
}

.reference-page {
  width: 80%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.reference-section {
  width: 100%;
}

.reference-card {
  text-align: center;
  padding: 10px;
  border-radius: 10px;
}

.reference-card-style {
  width: 400px;
  height: 330px;
  background-color: rgba(0,0,0,0.5) !important;
  padding: 20px !important;
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  text-align: center !important;
  cursor: pointer; /* Voeg deze regel toe om de cursor te veranderen */
}

.reference-photo {
  width: 89% !important;
  height: 72% !important;
  object-fit: cover;
  border-radius: 10px;
}
</style>