<template>
  <div class="reference-page">
    <div class="reference-section">
      <Carousel :items-to-show="itemsToShow" :loop="true" :autoplay="5000" :pauseAutoplayOnHover="true" :wrap-around="true">
        <Slide v-for="(reference, index) in references" :key="index">
          <div class="reference-card">
            <img :src="reference.photo" alt="Reference Photo" class="reference-photo" />
            <h3>{{ reference.name }}</h3>
            <p>{{ reference.text }}</p>
          </div>
        </Slide>
        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';
import axios from 'axios';

export default {
  name: 'ReferenceComponent',
  components: { Carousel, Slide, Navigation, Pagination },
  data() {
    return {
      references: [],
      itemsToShow: 3
    };
  },
  async created() {
    this.loadReferences();
  },
  mounted() {
    this.updateItemsToShow();
    window.addEventListener('resize', this.updateItemsToShow);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateItemsToShow);
  },
  methods: {
    async loadReferences() {
      try {
        const response = await axios.get('../../data.json');
        this.references = response.data.referenceComponent.references;
      } catch (error) {
        console.error('Error loading references:', error);
        this.references = []; 
      }
    },
    updateItemsToShow() {
      const width = window.innerWidth;
      if (width <= 768) {
        this.itemsToShow = 1;
      } else if (width <= 1024) {
        this.itemsToShow = 2;
      } else {
        this.itemsToShow = 3;
      }
    }
  }
};
</script>


<style lang="scss" scoped>
.reference-page {
  padding: 40px 20px;
  text-align: center;
  border-radius: 10px;
}

.reference-page h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
  font-family: 'Roboto', sans-serif;
}

.reference-page p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
  font-family: 'Roboto', sans-serif;
}

.reference-section {
  margin-top: 2rem;
}

.reference-section h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
  font-family: 'Roboto', sans-serif;
}

.reference-card {
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  margin: 10px;
  height: 100%;
  width: 100%;
}

.reference-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.reference-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 2px solid #ff9800;
}

.reference-section h3 {
  font-size: 1.5rem;
  margin: 0;
  color: #ff9800;
  font-family: 'Roboto', sans-serif;
}

.reference-section p {
  font-size: 1rem;
  margin: 0.5rem 0 0;
  color: #666;
  font-family: 'Roboto', sans-serif;
}

.reference-section p:last-child {
  font-style: italic;
  color: #333;
}

.carousel__icon {
  color: red;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .reference-card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
</style>